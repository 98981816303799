/** A password entry component. */

import React from 'react';

import { MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH} from '../constants';

import {
  PASSWORD_PROBLEMS,
} from '../util';
import * as logging from '../logging';

import {
  Icon,
  InputField,
  Text,
} from '@click-therapeutics-org/ct-components';

function renderProblems(problems) {
  const indicators = [];
  for (const [problemCode,  problemLabel] of Object.entries(PASSWORD_PROBLEMS))  {
    indicators.push(renderComplianceIndicator(problemCode, problemLabel, problems.has(problemCode)));
  }
  return (<div>{ indicators }</div>);
}

const renderComplianceIndicator = (elementKey, label, isProblem) => (
  <div key={'indicate-' + elementKey}>
    { isProblem
      ? <Icon icon='alert' size='small' color='critical' ariaLabel='Must fix'/>
      : <Icon icon='check' size='small' color='success' ariaLabel='Fine'/>
    }
    <Text element='span' variant={ isProblem ? 'critical' : 'secondary'}>&nbsp;{label}</Text>
  </div>
);

export const PasswordEntry = (props) => {
  const {
    value, error, onChange,
    disabled, label, name, id, autoFocus,
    passwordProblems,
  } = props;
  logging.debug('passwordProblems', passwordProblems);
  return (
    <div> {/* This div is to make the problems block flush with the input. */}
      <InputField id={id || "password"}
                  name = {name || "password"}
                  variant = "password"
                  mode = "password"
                  label= {label || 'Password'}
                  leadingIcon = "key"
                  placeholder=""
                  minLength = {MIN_PASSWORD_LENGTH}
                  maxLength = {MAX_PASSWORD_LENGTH}
                  value = {value || ''}
                  disabled = {disabled}
                  error = {error || ''}
                  autoFocus = {autoFocus}
                  onChange = {onChange}

      />
      {renderProblems(passwordProblems)}
    </div>
  );
};
