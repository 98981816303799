// Send a password reset request.

import React from 'react';
import { Redirect } from 'react-router';

import * as apiAccess from '../apiAccess.js';

import { navigationComplete } from '../actions/navigation';

import {
  Button,
  Heading,
  Loader,
  Text,
  TextLink,
} from '@click-therapeutics-org/ct-components';

import {
  FlexFormPage,
  FlexPageWithTermsFooter,
  SingleColumnLayout,
} from './common';

import { PasswordEntry } from './PasswordEntry';

import {
  findProblemsInPassword,
  eventHandlerPreventingDefault,
  getServerErrorMessage,
  isServerFailure,
  wireUp,
} from '../util';
import * as logging from '../logging';

import { actionType } from '../actions/password_reset_entry';

const PasswordResetEntry = (props) => {
  logging.debug('PasswordResetEntry render', props);
  props.navigationComplete('PasswordResetEntry');
  // Check transitional states.

  switch (props.actionType) {
  case actionType.INITIAL: {
    props.startTokenValidation(props.match.params.token); // The .match is inserted by react-router's <Route>.
    return renderCheckingResetLink();
  }
  // Validation is special, it does not show the editor yet:
  case actionType.validateToken.STARTED: return renderCheckingResetLink();
  case actionType.validateToken.ERROR: return renderValidationError();
  case actionType.validateToken.FAILURE: return renderTryLaterError('We are unable to set your new password right now');
  // Show editor; if an API call is underway is will render disabled.
  case actionType.validateToken.SUCCESS:
  case actionType.setPassword.STARTED:
  case actionType.setPassword.ERROR:
  case actionType.edit.INPUTS_CHANGE: return renderEditor(props);
  // API calls went well:
  case actionType.setPassword.SUCCESS: return (<Redirect to="/login"/>);  // Make the user log in with new password.
  // API calls failed, normal flow broken; all we can do is to show nicer diagnostics:
  case actionType.setPassword.FAILURE: return renderTryLaterError('Setting the new password failed.');
  default: return (
    // The "should never happen" branch.
    logging.error('PasswordResetEntry: unknown page state!', props),
    (<em>Something went wrong, check console.</em>)
  );
  }
};

const renderCheckingResetLink = () => (
  <FlexFormPage>
    <Loader variant='pageLoader' text='Checking your password reset link...'/>
  </FlexFormPage>
);

const renderValidationError = () => (
  <FlexPageWithTermsFooter>
    <SingleColumnLayout align='center'>
      <Heading variant="title3" element="h3">
        Expired password reset link
      </Heading>
      <Text
        element = "div"
        variant = "secondary"
      >
        Sorry! This password reset link
        is no longer valid, or expired.
      </Text>
      <TextLink href="/#/password-reset">RESEND RESET LINK</TextLink>
    </SingleColumnLayout>
  </FlexPageWithTermsFooter>
);

const renderTryLaterError = (message) => (
  <FlexPageWithTermsFooter>
    <SingleColumnLayout align='center'>
    <Heading variant="title3" element="h3">
      Something went wrong
    </Heading>
    <Text
      element = "div"
      variant = "primary"
    >
      Sorry! {message}.
    </Text>
    <Text
      element = "div"
      variant = "primary"
    >
      What you can do:
      <ul>
        <li>Reload this page.</li>
        <li>Click on the password reset link again later.</li>
        <li>Contact tech support.</li>
      </ul>
    </Text>
    </SingleColumnLayout>
  </FlexPageWithTermsFooter>
);

const renderEditor = (props) => {
  // Data entry state.
  const { password, errorMessage, inputOnChangeOf, submitFormWith } = props;
  const passwordProblems = findProblemsInPassword(password);
  console.debug('passwordProblems', passwordProblems);

  const submitButtonDisabled = props.running || (passwordProblems.size > 0);

  return (
  <FlexPageWithTermsFooter>
    <form onSubmit={submitFormWith(props)} style={{minWidth: '33%'}}>
      <SingleColumnLayout align='left'>
        <Heading variant="title2" element="h2">Set your password</Heading>
        <PasswordEntry value = {password}
                       disabled = {props.running}
                       label ='Choose a password'
                       error = {errorMessage}
                       passwordProblems = {passwordProblems}
                       autoFocus = {true}
                       onChange = {inputOnChangeOf('password')}
        />
        <Button submit={true}
                rounded={true}
                loading={props.running}
                disabled={submitButtonDisabled}
        >
          SET PASSWORD
        </Button>
      </SingleColumnLayout>
    </form>
  </FlexPageWithTermsFooter>
  );
};

PasswordResetEntry.myWiring = {
  mapStateToProps: (state, ownProps) =>  Object.assign({}, state.passwordResetEntry),
  mapDispatchToProps: dispatch => ({
    inputOnChangeOf: (controlName) => (event) => dispatch({
      type: actionType.edit.INPUTS_CHANGE,
      [controlName]: event.target.value,
    }),
    submitFormWith: (props) => eventHandlerPreventingDefault((event) => {
      dispatch({ type: actionType.setPassword.STARTED });
      callPasswordReset(props.token, props.password).then(dispatch);
    }),
    startTokenValidation: (rawToken) => {
      // NOTE! The unencoded token must not contain a literal percent sign.
      // It gets encoded as `%25`, and then decoded by the router back into `%`,
      // the only one of all URL-encoded characters. Then it breaks decodeURIComponent.
      // This looks like an upstream issue. The below coded defensively.
      try {
        const token = decodeURIComponent(rawToken);
        // We keep the decoded token in props.
        dispatch({ type: actionType.validateToken.STARTED, token });
        callIsValidPasswordResetToken(token).then(dispatch);
      } catch (error) {
        dispatch({ type: actionType.validateToken.ERROR, errorMessage: error.message});
      }
    },
    ...navigationComplete(dispatch),
  })
};


// TODO: Factor out and unify the parts common with register_by_token.
async function callIsValidPasswordResetToken(token) {
  logging.debug('callIsValidPasswordResetToken start; token', token);
  try {
    await apiAccess.isValidPasswordResetToken(token); // NOTE: the response is empty.
    return {
      type: actionType.validateToken.SUCCESS,
    };
  } catch (error) {
    logging.error('callIsValidPasswordResetToken error', error);
    const response = (isServerFailure(error)
        ? {
          type: actionType.validateToken.FAILURE,
          errorMessage: getServerErrorMessage(error),
        }
        : {
          type: actionType.validateToken.ERROR,
          errorMessage: 'Token no longer valid.'
        }
    );
    return response;
  }
}


async function callPasswordReset(token, password) {
  try {
    const response = await apiAccess.passwordResetComplete(token, password);
    logging.debug('callPasswordReset passwordResetComplete:', response);
    // The API returns nothing. Also, no state change on happy path.
    return {
      type: actionType.setPassword.SUCCESS,
    };
  } catch (error) {  // TODO: Refactor parts common with register_by_token.
    logging.error('callPasswordReset passwordResetComplete', error);
    const type = isServerFailure(error) ? actionType.setPassword.FAILURE : actionType.setPassword.ERROR;
    return {
      type,
      errorMessage: getServerErrorMessage(error),
    };
  }
}


const PasswordResetEntryC = wireUp(PasswordResetEntry);

export {
  PasswordResetEntryC,
};
