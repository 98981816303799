/**
Page and fragment layouts based on either Layout,
or a pack of FlexStacks.
Represent most common cases.
*/
import React from 'react';

import {
  FlexStack,
  Layout,
  LayoutColumn,
  Text,
} from '@click-therapeutics-org/ct-components';

/** A rigid brick intended for content like forms. Displays its children
 in a FlexStack.
 All props are passed to FlexStack, except .grow = .shrink = 0,
 and .inline = false.
*/
const FlexBrick = (props) => {
  const newProps = Object.assign({}, props);
  newProps.grow = 0;
  newProps.shrink = 0;
  newProps.inline = false;
  return FlexStack(newProps);
};

/** A <Layout> with a single column and a single <FlexStack> in it,
   where children go.
   The following props are supported:
   .align: controls FlrxStacks'a .align, default 'center'.
   .justify: controls FlrxStacks'a .justify, default 'between'.
*/
export const SingleColumnLayout = (props) => (
  <Layout template='Auth'>
    <LayoutColumn>
      <FlexStack direction='column'
                 justify={props.justify || 'between'}
                 align={props.align || 'center'}>
        {props.children}
      </FlexStack>
    </LayoutColumn>
  </Layout>
);

/** A whole-page element With a sigle column.
  The following props are supported:
  .contentAlign = "left" is align-content inside the FlexBrick,
    controls positioning of this element's children.
  .contentJustify = "left" is justify-content inside the FlexBrick,
    controls justification of this element's children.
   Mobile-friendly.
*/
export const FlexFormPage = (props) => (
  <FlexStack
    flex = {true}
    direction = "column"
    align = "center"
    justify = "space-between"  /* Content inside. */
    spacing = "none"
  >
    <SingleColumnLayout
      align = {props.contentAlign || "left"} /* On the page. */
      justify = {props.contentJustify || "left"}  /* Content inside. */
    >
      {props.children}
    </SingleColumnLayout>
  </FlexStack>
);

/** A whole-page element like FlexFormPage,
   but with a block of "Terms and Conditions" and "Privacy
   Policy" links, plus copyright, near the top of the page.
   Ignores any props but .choldren.
*/
export const FlexPageWithTermsFooter = ({children}) => (
  // TODO: Use <Layout> with a proper template when it's ready.

  <Layout template="Auth">
    <LayoutColumn>
      <FlexStack grow direction="column" align="center" justify="between">
        <FlexStack inline direction="column">
          {children}
        </FlexStack>
      </FlexStack>
    </LayoutColumn>
    <LayoutColumn>
      <FlexStack direction="column" align="center">
        <FlexBrick justify = "center">
          <Text variant="secondary" size="small" align="center">
            &copy; Click Therapeutics, Inc. 2020
          </Text>
        </FlexBrick>
      </FlexStack>
    </LayoutColumn>
  </Layout>
);
